import React from 'react'
import {
  MenuItemInterface,
  SingleItemHrefInterface,
} from 'shared/components/header/types/menu-interface'
import { ABSOLUTE_PATH_PREFIX } from 'shared/constants/base-path-url'
import { AdminRolesEnum, UserRolesEnum, UserStatusEnum } from 'shared/enums/user-roles-enum'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import AutomationsIcon from 'shared/icons/automations-icon'
import BlogsIcon from 'shared/icons/blogs-icon'
import ContactsIcon from 'shared/icons/contacts-icon'
import DashboardIcon from 'shared/icons/dashboard-icon'
import EmailsIcon from 'shared/icons/emails-icon'
import FunnelsIcon from 'shared/icons/finnels-icon'
import HelpIcon from 'shared/icons/help-icon'
import ManInSuit from 'shared/icons/man-in-suit'
import ProductsIcon from 'shared/icons/products-icon'
import SalesIcon from 'shared/icons/sales-icon'
import UpgradeIcon from 'shared/icons/upgrade-icon'

export const useMenuItems = () => {
  const { t } = useLocoTranslation()

  const upgradeAccountItem: SingleItemHrefInterface = {
    href: '/profile/user-plan',

    mobileIconRenderer: () => <UpgradeIcon />,
    name: t('header.top_menu.upgrade'),
  }

  const templateItems: MenuItemInterface['item'][] = [
    {
      name: t('header.top_menu.admin.email_templates'),
      href: '/management/email-templates',
      adminRoles: [AdminRolesEnum.contentManager, AdminRolesEnum.admin, AdminRolesEnum.superAdmin],
    },
    {
      name: t('header.top_menu.admin.page_templates'),
      href: '/management/page-templates',
      adminRoles: [AdminRolesEnum.contentManager, AdminRolesEnum.admin, AdminRolesEnum.superAdmin],
    },
    {
      name: t('header.top_menu.admin.blog_templates'),
      href: '/management/blog-templates',
      adminRoles: [AdminRolesEnum.contentManager, AdminRolesEnum.admin, AdminRolesEnum.superAdmin],
    },
    {
      name: t('header.top_menu.admin.funnel_templates'),
      href: '/management/funnel-templates',

      adminRoles: [AdminRolesEnum.contentManager, AdminRolesEnum.admin, AdminRolesEnum.superAdmin],
    },
  ]
  const menuItemsAffiliate: MenuItemInterface[] = [
    {
      item: {
        name: t('header.top_menu.dashboard'),
        dropdownItems: [
          {
            name: t('header.top_menu.affiliate_dashboard'),
            href: '/affiliate-dashboard',

            hideForWorkspace: true,
            showForRoles: [UserRolesEnum.affiliate],
          },
          {
            name: t('header.top_menu.marketplace'),
            href: `${ABSOLUTE_PATH_PREFIX}/marketplace`,
          },
          {
            name: t('header.top_menu.student_dashboard'),
            href: `${ABSOLUTE_PATH_PREFIX}/school?tab=explore_courses`,
            hideForRoles: [UserRolesEnum.student],
          },
        ],
        mobileIconRenderer: () => <DashboardIcon />,
      },
    },
    {
      item: {
        name: t('header.top_menu.admin.admin'),
        isAdmin: true,
        dropdownItems: templateItems,
        mobileIconRenderer: () => <ManInSuit />,
      },
    },
    {
      item: {
        name: t('header.top_menu.help'),
        href: `${ABSOLUTE_PATH_PREFIX}/help`,
        target: '__blank',
        mobileIconRenderer: () => <HelpIcon />,
      },
    },
  ]

  const menuItemsAffiliateStudent: MenuItemInterface[] = [
    {
      item: {
        name: t('header.top_menu.dashboard'),
        dropdownItems: [
          {
            name: t('header.top_menu.affiliate_dashboard'),
            href: '/affiliate-dashboard',

            hideForWorkspace: true,
            showForRoles: [UserRolesEnum.affiliate],
          },
          {
            name: t('header.top_menu.student_dashboard'),
            showForRoles: [UserRolesEnum.student],
            href: `${ABSOLUTE_PATH_PREFIX}/school`,
          },
          {
            name: t('header.top_menu.student_dashboard'),
            href: `${ABSOLUTE_PATH_PREFIX}/school?tab=explore_courses`,
            hideForRoles: [UserRolesEnum.student],
          },
          {
            name: t('header.top_menu.marketplace'),
            href: `${ABSOLUTE_PATH_PREFIX}/marketplace`,
          },
        ],
        mobileIconRenderer: () => <DashboardIcon />,
      },
    },
    {
      item: {
        name: t('header.top_menu.admin.admin'),
        isAdmin: true,
        dropdownItems: templateItems,
        mobileIconRenderer: () => <ManInSuit />,
      },
    },
    {
      item: {
        name: t('header.top_menu.help'),
        href: `${ABSOLUTE_PATH_PREFIX}/help`,
        target: '__blank',
        mobileIconRenderer: () => <HelpIcon />,
      },
    },
  ]

  const menuItems: MenuItemInterface[] = [
    {
      item: {
        name: t('header.top_menu.dashboard'),
        dropdownItems: [
          {
            name: t('header.top_menu.main_dashboard'),
            href: '/',
            hideForWorkspaceAssistant: true,
          },
          {
            name: t('header.top_menu.affiliate_dashboard'),
            href: '/affiliate-dashboard',

            hideForWorkspace: true,
            showForRoles: [UserRolesEnum.affiliate],
          },
          {
            name: t('header.top_menu.student_dashboard'),
            showForRoles: [UserRolesEnum.student],
            href: `${ABSOLUTE_PATH_PREFIX}/school`,
          },
          {
            name: t('header.top_menu.student_dashboard'),
            href: `${ABSOLUTE_PATH_PREFIX}/school?tab=explore_courses`,
            hideForRoles: [UserRolesEnum.student],
          },
          {
            name: t('header.top_menu.marketplace'),
            href: `${ABSOLUTE_PATH_PREFIX}/marketplace`,
          },
          // { name: t('header.top_menu.stores'), href: '/stores' },
        ],
        mobileIconRenderer: () => <DashboardIcon />,
      },
    },
    {
      item: {
        name: t('header.top_menu.contacts'),
        dropdownItems: [
          {
            name: t('header.top_menu.contacts'),
            href: '/contacts',
            assistantLink: '/assistant/contacts',
          },
          { name: t('header.top_menu.tags'), href: '/tags' },
        ],
        mobileIconRenderer: () => <ContactsIcon />,
      },
    },
    // {
    //   item: {
    //     name: t('header.top_menu.calendar'),
    //     href: '/calendar',
    //   }
    // },
    {
      item: {
        name: t('header.top_menu.funnels'),
        href: '/funnels',

        mobileIconRenderer: () => <FunnelsIcon />,
      },
    },
    {
      item: {
        name: t('header.top_menu.emails'),
        dropdownItems: [
          { name: t('header.top_menu.newsletters'), href: '/newsletters' },
          { name: t('header.top_menu.campaigns'), href: '/campaigns' },
          {
            name: t('header.top_menu.email_statistics'),
            href: '/email-statistics',
          },
        ],
        mobileIconRenderer: () => <EmailsIcon />,
      },
    },
    {
      item: {
        name: t('header.top_menu.blogs'),
        href: '/blogs',

        mobileIconRenderer: () => <BlogsIcon />,
      },
    },
    {
      item: {
        name: t('header.top_menu.automations'),
        dropdownItems: [
          { name: t('header.top_menu.rules'), href: '/automation-rules' },
          { name: t('header.top_menu.workflows'), href: '/workflows' },
        ],
        mobileIconRenderer: () => <AutomationsIcon />,
      },
    },
    {
      item: {
        name: t('header.top_menu.products'),
        dropdownItems: [
          {
            name: t('header.top_menu.physical_product'),
            href: '/products',
          },
          {
            name: t('header.top_menu.orders'),
            href: '/orders',
          },
          { name: t('header.top_menu.coupons'), href: '/coupons' },
          {
            name: t('header.top_menu.courses'),
            href: '/courses',
          },
          {
            name: t('header.top_menu.communities'),
            href: '/communities',
          },
        ],
        mobileIconRenderer: () => <ProductsIcon />,
      },
    },
    {
      item: {
        name: t('header.top_menu.sales'),
        dropdownItems: [
          {
            name: t('header.top_menu.transactions'),
            href: '/payment/transactions',
          },
          {
            name: t('header.top_menu.subscriptions'),
            href: '/payment/subscriptions',
          },
          {
            name: t('header.top_menu.affiliate_invoices'),
            href: '/payment/affiliate-invoices',

            hideForWorkspaceAssistant: true,
          },
          {
            name: t('header.top_menu.affiliate_program'),
            href: '/affiliate-program',
            hideForWorkspace: true,
          },
        ],
        mobileIconRenderer: () => <SalesIcon />,
      },
    },
    {
      item: {
        name: t('header.top_menu.admin.admin'),
        isAdmin: true,
        dropdownItems: [
          {
            name: t('header.top_menu.admin.users'),
            href: '/management/users',
            adminRoles: [AdminRolesEnum.support, AdminRolesEnum.admin, AdminRolesEnum.superAdmin],
          },
          {
            name: t('header.top_menu.admin.templates'),
            dropdownItems: templateItems,
          },
          {
            name: t('header.top_menu.admin.customers'),
            href: '/management/user-customer-list',
            adminRoles: [
              AdminRolesEnum.mailingManager,
              AdminRolesEnum.support,
              AdminRolesEnum.admin,
              AdminRolesEnum.superAdmin,
            ],
          },
          {
            name: t('header.top_menu.admin.emails'),
            href: '/management/emails-delivery-statistics',
            adminRoles: [AdminRolesEnum.support, AdminRolesEnum.admin, AdminRolesEnum.superAdmin],
          },
          {
            name: t('header.top_menu.admin.user_plans'),
            href: '/management/user-plans',
            adminRoles: [AdminRolesEnum.admin, AdminRolesEnum.superAdmin],
            hideForWorkspaceAssistant: true,
          },
          {
            name: t('header.top_menu.admin.domains_blocked'),
            href: '/management/blocked-mailbox-provider',
            adminRoles: [AdminRolesEnum.support, AdminRolesEnum.admin, AdminRolesEnum.superAdmin],
          },
          {
            name: t('header.top_menu.admin.domains_white_listed'),
            href: '/management/white-list-mailbox',
            adminRoles: [AdminRolesEnum.support, AdminRolesEnum.admin, AdminRolesEnum.superAdmin],
          },
          {
            name: t('header.top_menu.admin.suspicious_content'),
            dropdownItems: [
              {
                name: t('header.top_menu.admin.suspicious_page_content_keywords'),
                href: '/management/suspicious-page-content-keyword',
                adminRoles: [
                  AdminRolesEnum.support,
                  AdminRolesEnum.admin,
                  AdminRolesEnum.superAdmin,
                ],
              },
              {
                name: t('header.top_menu.admin.suspicious_email_keywords'),
                href: '/management/suspicious-email-keyword',
                adminRoles: [
                  AdminRolesEnum.support,
                  AdminRolesEnum.admin,
                  AdminRolesEnum.superAdmin,
                ],
              },
              {
                name: t('header.top_menu.admin.suspicious_email_address'),
                href: '/management/suspicious-email-address',
                adminRoles: [
                  AdminRolesEnum.support,
                  AdminRolesEnum.admin,
                  AdminRolesEnum.superAdmin,
                ],
              },
            ],
          },
          {
            name: t('header.top_menu.admin.statistics'),
            href: '/management/statistics',
            adminRoles: [AdminRolesEnum.superAdmin],
            hideForWorkspaceAssistant: true,
          },
        ],
        mobileIconRenderer: () => <ManInSuit />,
      },
    },
    {
      item: {
        name: t('header.top_menu.help'),
        href: `${ABSOLUTE_PATH_PREFIX}/help`,
        target: '__blank',
        mobileIconRenderer: () => <HelpIcon />,
      },
    },
  ]

  const getMenuItems = (userStatus: UserStatusEnum) => {
    switch (userStatus) {
      case UserStatusEnum.affiliate: {
        return menuItemsAffiliate
      }
      case UserStatusEnum.affiliate_student: {
        return menuItemsAffiliateStudent
      }
      default: {
        return menuItems
      }
    }
  }
  return { getMenuItems, upgradeAccountItem }
}
